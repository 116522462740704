.app {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 70%;
  max-width: 420px;
  padding: 20px;
  border-bottom: 4px;
  position: relative;
}

@media screen and (max-width: 540px) {
  .container {
    max-width: inherit;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  .app {
    height: 100%;
  }
}
